$pageMargin: 1.5cm 2cm 1.5cm 2cm;
$imageMaxWidth: 10cm;
$imageMaxHeight: 10cm;

$base: 0.25cm;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;


$searchThumbWidth: 20%;
$previewThumbWidth: 20%;

$logoHeight: 1.5cm;
