.ldi-overlay {
	display: none;

	body[data-ldi-current-context="overlay"] & {
		display: block;
	}


	&__actions {
		display: none;
	}
}
