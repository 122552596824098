*, *::before, *::after {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

svg, svg use {
	box-sizing: content-box;
}


button {
	border-radius: 0;
}


html, body {
	@include sansSerif();
	@include ldi-fontSize(base);
	text-align: justify;
	color: $baseColor;
}


body.ldi-js-searchMode {
}


h1 {
	@include ldi-fontSize(h1);
}

h2 {
	@include ldi-fontSize(h1);
}

h3 {
	@include ldi-fontSize(h2);
}

h4, h5, h6 {
	@include ldi-fontSize(base);
}


h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	padding: 0;
	page-break-after: avoid;
}

p {
	margin-top: 0;
	margin-bottom: $base;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: $baseColor;
	font-weight: bold;
	text-decoration: none;
	border-bottom: 1px solid $baseBorderColor;

	// @include media('print') {
	&::after {
		content: " (" attr(href) ") ";
		font-weight: normal;
		word-break: break-all;
 	}

}

img {
	max-width: $imageMaxWidth;
	max-height: $imageMaxHeight;
}

figure {
	margin: 0;
}

figcaption {
	// @include ldi-fontSize(caption);
	text-align: left;
	// margin-top: $base;
}

span[data-type="svg"] {
	display: none;
}



@page {
	margin: $pageMargin;
}
