.ldi-tag {
	@include sansSerif();
	@include ldi-fontSize(base);
	padding: ($base / 2) $base;
	border: 1px solid $baseBorderColor;
	background-color: transparent;
	color: $baseColor;

	&--selected {
		font-weight: bold;
	}


	&__amount {
		opacity: 0.6;

		&::before {
			content: '(';
		}

		&::after {
			content: ')';
		}
	}

	&__icon {
		display: none;
	}
}

a.ldi-tag {
	font-weight: normal;
	border: 1px solid $baseBorderColor;

	&::after {
		display: none;
	}
}
