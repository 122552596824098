.ldi-preview {

	&__title {
		margin-bottom: $base;
	}

	&__thumb {
		display: none;
	}
}
