$fontSizes: (
	base: (
		size: 10pt,
		line-height: 1.55
	),
	small: (
		size: 9pt,
		line-height: 1.55
	),
	h1: (
		size: 18pt,
		line-height: 1.2
	),
	h2: (
		size: 16pt,
		line-height: 1.12
	),

	important: (
		size: 12pt,
		line-height: 1.55
	),

	caption: (
		size: 9pt,
		line-height: 1.3
	)
);
