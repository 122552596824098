.ldi-blockVideo {

	&__playerBtn {

		&::after {
			content: " Video URL: " attr(href);
			display: block;
			font-weight: bold;
			word-break: break-all;
		}
	}


	&__player {
		display: none;
	}

}

