
// user generated content Style:
.ldi-ugc {


	q, blockquote {
		quotes: "»" "«" "\00AB" "\00BB";
	}

	blockquote {
		margin: 0 0 0 1em;

		> p {
			&::before {
				content: open-quote;
			}


			&::after {
				content: close-quote;
			}
		}
	}

	figure {

		&.video {

			> iframe {
				display: none;
			}
		}
	}

	ul {
		padding-left: 0;

		> li {
			position: relative;
			margin-left: 1em;
			padding-left: 0.5em;



			&::before {
				content: '–';
				position: absolute;
				left: -1em;
			}
		}
	}

	a {
	}


	iframe {
		display: none;
	}


	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid $baseBorderColor;
		margin: 1em 0;
		padding: 0;
	}


	h2 + * {
		margin-top: $base5;
	}

	* + h3 {
		margin-top: $base5;
	}

	h2 + h3 {
		margin-top: $base3;
	}

	h3 + * {
		margin-top: $base;
	}

	* + figure {
		margin-top: $base3;
	}

	h3 + figure {
		margin-top: $base2;
	}

	figure + * {
		margin-top: $base3;
	}

}
