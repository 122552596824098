.ldi-header {
	@include clearfix();
	padding-bottom: $base2;
	border-bottom: 1px solid $baseBorderColor;
	margin-bottom: $base4;

	&__mainTitle,
	&__subMenu {
		display: none;
	}

	&__logo {
		float: left;
		border: 0;
		display: block;
		padding: 0;


		&::after {
			display: none;
		}

		img {
			display: block;
			height: $logoHeight;
			margin: 0;
		}
	}


	&__actions {
		display: none;
	}


	.ldi-header__qrCode {
		float: right;

		img {
			height: $logoHeight;
		}
	}


	&__wrapper--account {
		display: none;
	}
}
