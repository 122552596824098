.ldi-previewList {

	&__item {
		page-break-inside: avoid;

		& + & {
			margin-top: $base3;
		}
	}
}
