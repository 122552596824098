.ldi-search {

	&__toolbar {
		display: none;

		body.ldi-js-searchMode & {
			display: block;
		}
	}


	&__label {
		display: block;
	}

	&__input {
		@include sansSerif();
		@include ldi-fontSize(important);
		width: 100%;
		display: block;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: $base2;
		padding: $base;
		border: 1px solid $baseBorderColor;
	}


	&__close {
		display: none;
	}

}
