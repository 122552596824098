.ldi-projectView {

	&__sections {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__section {
		margin: 0;
		padding: 0;

		& + & {
			margin-top: $base7;
		}
	}


	&__sectionTitle {
		margin-bottom: $base3;
		page-break-after: avoid;
	}

	&__fields,
	&__imagefields {
		list-style: none;
		margin: 0;
		padding: 0;
		page-break-before: avoid;
	}

	&__field {
		page-break-inside: avoid;
		border-bottom: 1px solid currentColor;
		padding-top: $base2;
		padding-bottom: $base2;

		&:first-child {
			border-top: 1px solid currentColor;
		}

		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&--image {
			flex-direction: row-reverse;
		}
	}


	&__fieldLabel {
		font-weight: bold;
		padding-bottom: $base / 2;

		padding-bottom: 0;
		padding-right: $base2;
		width: 40%;
		text-align: left;
	}


	&__fieldValue {
		width: 60%;
	}

	&__imagefield {

		& + & {
			margin-top: $base2;
		}

	}


	&__imagefieldLabel {
		font-weight: bold;
		padding-bottom: $base / 2;
		text-align: left;
	}

	&__imageWrapper {
		padding-bottom: 0;
		width: 60%;
	}


	&__imageFields {
		width: 40%;
		padding-right: $base2;
	}

	&__image {
		width: 100%;
		height: auto;
	}

}
