.ldi-tagsList {
	//display: none;

	&__items {
		@include clearfix();
		list-style-type: none;
		margin: 0;
		padding: 0;
		line-height: 2.5;
	}


	&__item {
		margin: 0;
		padding: 0;
		float: left;
		margin-right: $base;
		display: none;

		&--selected {
			display: block;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
