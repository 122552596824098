.ldi-block {

	& + & {
		margin-top: $base5;
	}

	&__text {

		&--important {
			@include ldi-fontSize(important);
		}
	}


	&__bgImg {
		display: none;


		.ldi-block--video & {
			margin-top: $base4;
			display: block;
		}
	}
}
